<template>
  <div id="privacy-policy">
    <Menu />
    <div class="wrapper">
      <div class="text-wrapper">
        <h1 class="title">Privacy Policy</h1>
        <p class="text">
          B2B Print Pro has created this Privacy Policy to show how your privacy
          is protected on our website, and disclose our practices regarding
          gathering, distribution and protection of information.
        </p>
        <h2 class="title">Compliance</h2>
        <p class="text text2">
          By accessing this website, you agree to the terms of this Privacy
          Policy. Otherwise, please leave immediately and do not access or use
          our website.
        </p>
        <h2 class="title title3">Gathering of information</h2>
        <p class="text text3">
          To provide better services to our clients, B2B Print Pro collects the
          following information:
        </p>

        <ul class="list">
          <li class="list__item text">
            Information you choose to submit (e.g. your name, last name, e-mail,
            company title) to contact us, access downloads or view specific
            content, such as newsletters, etc.;
          </li>
          <li class="list__item text">
            Technical information collected during the use of our website, e.g.
            your IP address, browser type, platform, ISP, referring URLs,
            date/time stamps, country/states, etc.
          </li>
          <li class="list__item text">
            Please note, your actual geo location information may be collected
            and processed.
          </li>
        </ul>

        <h2 class="title">Use of information</h2>
        <p class="text">
          Any information collected by B2B Print Pro is processed in accordance
          with this Privacy Policy. We may use any non-personal information we
          collect for web site diagnostics, maintenance and administration
          purposes. <br />
          <br />
          We will not sell, share, or rent this information to any person or
          organization.
        </p>
        <h2 class="title">Information security</h2>
        <p class="text">
          We treat your personal information as Confidential and protect it by
          encrypting and storing it on secure servers, with only limited B2B
          Print Pro personnel having access to it.
        </p>
        <h2 class="title">Links</h2>
        <p class="text">
          Codex Software has no control over and thereby is not responsible for
          the content, quality, reliability or privacy policies of any 3rd party
          websites our website may be linking to.
        </p>
        <h2 class="title">Changes to the Policy</h2>
        <p class="text">
          B2B Print Pro reserves the right to modify or amend this Privacy
          Policy at its own discretion. The changes can be viewed here or
          elsewhere at our website. By continuing the use of our website, you
          accept these changes.
        </p>
        <h2 class="title">Got questions?</h2>
        <p class="text text-last">Please get in touch for more information.</p>
        <router-link to="/contact-us">
          <button class="contact-us">Contact us</button>
        </router-link>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../Home/Footer";
import Menu from "../Menu";

export default {
  components: {
    Footer,
    Menu,
  },
};
</script>

<style lang="scss">
#privacy-policy {
  // background-image: url(../../assets/privacy_bg.jpg);
  background: linear-gradient(
    165.33deg,
    rgba(168, 214, 239, 0.8) 0%,
    rgba(205, 236, 253, 0.8) 27.17%,
    rgba(233, 247, 255, 0.8) 67.19%,
    rgba(241, 247, 250, 0.8) 100%
  );

  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;

  .menu {
    margin: 0px auto 131px;
  }

  .wrapper {
    width: 1128px;
    margin: 0 auto;

    .text-wrapper {
      width: 557px;

      .text {
        margin-bottom: 48px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #2c3e66;
      }

      .text2 {
        margin-bottom: 57px;
      }

      .text3 {
        margin-bottom: 32px;
      }

      .text-last {
        margin-bottom: 43px;
      }

      .title {
        margin-bottom: 10px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;

        color: #2c3e66;
      }

      .title3 {
        margin-bottom: 0px;
      }

      h1 {
        font-size: 48px;
        line-height: 56px;
      }

      h2 {
        font-size: 32px;
        line-height: 48px;
      }

      .list {
        list-style-image: url("../../assets/list_marker.svg");
        padding: 0px;
        padding-left: 27px;
        padding-bottom: 16px;

        .list__item {
          padding-left: 12px;
          margin-bottom: 32px;
          width: 531px;
        }
      }

      .contact-us {
        padding: 12px 40px;
        margin-bottom: 72px;
        background: #2c3e66;
        border-radius: 10px;
        border: 2px solid #2c3e66;
        outline: none;
        cursor: pointer;

        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #privacy-policy {
    .wrapper {
      width: 867px;

      .text-wrapper {
        width: 657px;

        .list {
          .list__item {
            width: 557px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #privacy-policy {
    .wrapper {
      width: 680px;

      .text-wrapper {
        width: 657px;

        .list {
          .list__item {
            width: 557px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  #privacy-policy {
    .menu {
      margin: 0px auto 85px;
    }

    .wrapper {
      width: 87%;

      .text-wrapper {
        width: 100%;

        .list {
          .list__item {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  #privacy-policy {
    .menu {
      margin-bottom: 60px;
    }

    .wrapper {
      width: 315px;
      margin: 0 auto;

      .text-wrapper {
        width: 257px;
        .list {
          width: 257px;

          .list__item {
            width: 257px;
          }
        }
      }
    }
  }
}
</style>
