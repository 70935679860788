<template>
  <section class="FAQ" id="faq">
    <h2 class="FAQ-title">{{ $t("main.faq.title") }}</h2>

    <div class="questions-wrapper">
      <div class="column">
        <Question
          v-for="question of getQuestions.slice(0, getQuestions.length / 2)"
          v-bind:question="question"
          :key="question.title"
        />
      </div>
      <div class="column">
        <Question
          v-for="question of getQuestions.slice(getQuestions.length / 2)"
          v-bind:question="question"
          :key="question.title"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Question from "./Question";

export default {
  components: {
    Question,
  },

  computed: {
    getQuestions() {
      return [
        {
          title: this.$t("main.faq.question2"),
          answer: this.$t("main.faq.answer2"),
        },
        {
          title: this.$t("main.faq.question3"),
          answer: this.$t("main.faq.answer3"),
        },
        {
          title: this.$t("main.faq.question4"),
          answer: this.$t("main.faq.answer4"),
        },
        {
          title: this.$t("main.faq.question6"),
          answer: this.$t("main.faq.answer6"),
        },
        {
          title: this.$t("main.faq.question7"),
          answer: this.$t("main.faq.answer7"),
        },
        {
          title: this.$t("main.faq.question8"),
          answer: this.$t("main.faq.answer8"),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.FAQ {
  width: 1128px;
  margin: 0 auto;
  padding: 120px 0;
  min-height: 100vh;

  .FAQ-title {
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #2c3e66;
  }

  .questions-wrapper {
    display: flex;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
      width: 49%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .FAQ {
    width: 843px;
    margin: 0 auto;
    padding: 140px 0;
  }

  .question {
    margin-bottom: 8px;

    .answer {
      width: 352px;
    }
  }
}

@media screen and (max-width: 768px) {
  .FAQ {
    width: 680px;
    padding: 120px 0;
  }

  .question {
    margin-bottom: 8px;

    .answer {
      width: 283px;
    }

    .question-title {
      width: 246px;
    }
  }
}

@media screen and (max-width: 540px) {
  .FAQ {
    width: 87%;

    .questions-wrapper {
      flex-direction: column;

      .column {
        width: 100%;
      }
    }
  }

  .question {
    .question-title {
      width: 80%;
    }

    .answer {
      width: 94%;
    }
  }
}

@media screen and (max-width: 360px) {
  .FAQ {
    width: 328px;
    padding: 120px 0;

    .questions-wrapper {
      flex-direction: column;

      .column {
        width: 100%;
      }
    }
  }

  .question {
    margin-bottom: 8px;

    .answer {
      width: 283px;
    }

    .question-title {
      width: 246px;
    }
  }
}
</style>
