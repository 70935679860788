<template>
  <div class="blog-component">
    <Menu />
    <div class="blog-wrapper">
      <h1 class="title">{{ $t("blog.blogTitle") }}</h1>
      <ul class="categories">
        <li class="category">{{ $t("blog.categories.processAutomation") }}</li>
        <li class="category">{{ $t("blog.categories.printIndustry") }}</li>
        <li class="category">{{ $t("blog.categories.featureLaunch") }}</li>
        <li class="category">{{ $t("blog.categories.news") }}</li>
        <li class="category">{{ $t("blog.categories.printSales") }}</li>
      </ul>
      <div class="posts-wrapper">
        <div
          class="post__card"
          v-for="(post, i) of posts"
          :key="i"
          :class="{ main__post: i === 0 }"
        >
          <div class="post-image__wrapper">
            <img
              :src="require(`@/assets/${post.imageUrl}`)"
              class="post__image"
              alt="img"
            />
            <button class="post__category">
              {{ $t(`blog.posts[${i}].category`) }}
            </button>
          </div>

          <div class="post__content">
            <h2 class="post__title">{{ $t(`blog.posts[${i}].title`) }}</h2>
            <p class="post__text">{{ $t(`blog.posts[${i}].shortText`) }}</p>
            <div class="wrapper">
              <p class="time-for-read">
                {{ $t(`blog.posts[${i}].timeForRead`) }}
              </p>
              <router-link :to="`/post/${i}`" class="route">
                <p class="open-post-btn">
                  {{ $t("blog.openPostLinkName") }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Menu from "../Menu";
import Footer from "../Home/Footer";
import localeJSON from "../../locales/en.json";

export default {
  components: {
    Menu,
    Footer,
  },

  data() {
    return {
      posts: localeJSON.blog.posts,
    };
  },
};
</script>

<style lang="scss">
.blog-component {
  background: linear-gradient(
    165.33deg,
    rgba(168, 214, 239, 0.8) 0%,
    rgba(205, 236, 253, 0.8) 27.17%,
    rgba(233, 247, 255, 0.8) 67.19%,
    rgba(241, 247, 250, 0.8) 100%
  );
  font-family: "Montserrat", sans-serif;

  .menu {
    margin: 0px auto 67px;
  }

  .blog-wrapper {
    width: 78.3%;
    max-width: 1128px;
    margin: 0 auto;

    .title {
      margin-bottom: 26px;
      font-weight: bold;
      font-size: 48px;
      color: #2c3e66;
    }

    .categories {
      display: flex;
      justify-content: space-between;
      width: 60.3%;
      margin-bottom: 34px;
      padding: 0;
      list-style-type: none;
      font-size: 16px;
      font-weight: bold;
      color: #a6cbdf;
    }

    .posts-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .post__card {
        display: flex;
        flex-direction: column;
        width: 32%;
        margin-bottom: 82px;

        .post-image__wrapper {
          position: relative;
          height: 175px;
          margin-bottom: 24px;

          .post__image {
            width: 100%;
            height: 100%;
          }

          .post__category {
            position: absolute;
            bottom: 12px;
            right: 12px;
            padding: 2px 20px;
            background: rgba(188, 222, 240, 0.8);
            border: none;
            outline: none;
            border-radius: 40px;

            font-family: inherit;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }

        .post__content {
          padding: 0 12px;
          color: #2c3e66;

          .post__title {
            margin-bottom: 12px;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
          }

          .post__text {
            margin-bottom: 26px;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
          }

          .wrapper {
            display: flex;
            justify-content: space-between;

            .time-for-read {
              font-weight: 500;
              font-size: 16px;
              color: #a6cbdf;
            }

            .route {
              text-decoration: none;
              color: #2c3e66;
            }

            .open-post-btn {
              position: relative;
              padding-right: 38px;
              font-family: inherit;
              font-weight: 500;
              font-size: 16px;
              cursor: pointer;

              &::after {
                position: absolute;
                top: -2px;
                right: 2px;
                content: "";
                width: 24px;
                height: 24px;
                background-image: url(../../assets/post_arrow.svg);
                background-position: center;
                background-repeat: no-repeat;
              }

              &:hover {
                color: #00c0ff;
              }

              &:hover:after {
                background-image: url(../../assets/post_arrow_hover.svg);
              }
            }
          }
        }
      }

      .main__post {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .post-image__wrapper {
          width: 744px;
          height: 360px;
          margin-bottom: 0;

          .post__image {
            width: 744px;
          }

          .post__category {
            bottom: 24px;
            right: 24px;
          }
        }

        .post__content {
          padding: 0;
          padding-left: 48px;

          .post__text {
            margin-bottom: 63px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blog-component {
    .menu {
      margin: 0px auto 87px;
    }

    .blog-wrapper {
      width: 85.2%;

      .categories {
        width: 78%;
      }

      .posts-wrapper {
        .post__card {
          width: 48.6%;
        }

        .main__post {
          width: 100%;

          .post-image__wrapper {
            width: 488px;
            height: 360px;

            .post__image {
              width: 488px;
              height: 360px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .blog-component {
    .menu {
      margin: 0px auto 80px;
    }

    .blog-wrapper {
      width: 88.5%;

      .categories {
        width: 100%;
      }

      .posts-wrapper {
        .post__card {
          width: 48.2%;

          .post__content {
            padding: 0;
          }
        }

        .main__post {
          flex-direction: column;
          width: 100%;

          .post-image__wrapper {
            width: 100%;
            height: 360px;

            .post__image {
              width: 100%;
              height: 360px;
            }
          }

          .post__content {
            padding: 0;
            padding-top: 25px;

            .post__text {
              margin-bottom: 26px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .blog-component {
    .blog-wrapper {
      .posts-wrapper {
        .post__card {
          width: 100%;
          .post-image__wrapper {
            height: 220px;

            .post__image {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .blog-component {
    .blog-wrapper {
      .categories {
        display: none;
      }
      .posts-wrapper {
        .post__card {
          width: 100%;
          .post-image__wrapper {
            height: 190px;

            .post__image {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .blog-component {
    .blog-wrapper {
      .posts-wrapper {
        .post__card {
          width: 100%;
          .post-image__wrapper {
            height: 175px;

            .post__image {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .blog-component {
    .blog-wrapper {
      .posts-wrapper {
        .post__card {
          width: 100%;
          .post-image__wrapper {
            height: 165px;

            .post__image {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
