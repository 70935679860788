<template>
  <div class="logo-wrapper">
    <router-link to="/">
      <img
        class="logo"
        :src="require(`../assets/${this.$props.url}`)"
        alt="logo"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["url"],
};
</script>

<style lang="scss">
.logo-wrapper {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    text-decoration: none;

    .logo {
      width: 204px;
    }
  }
}
</style>
