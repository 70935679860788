<template>
  <div class="app-container">
    <ChooseLanguage @closed="closeChooseLanguage" v-if="!isLanguageChoosen" />
    <router-view></router-view>
    <Cookies v-if="!this.acceptedCookies" v-on:acceptCookies="acceptCookies" />
  </div>
</template>

<script>
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import Cookies from "./components/Cookies";
import ChooseLanguage from "./components/ChooseLanguage";

export default {
  name: "App",
  components: {
    Home,
    ContactUs,
    Cookies,
    ChooseLanguage,
  },

  mounted() {
    if (this.language) {
      this.$i18n.locale = this.language.value;
    }
  },

  data() {
    return {
      acceptedCookies: localStorage.getItem("acceptedCookies") ?? false,
      language: JSON.parse(localStorage.getItem("language")),
      isLanguageChoosen:
        JSON.parse(localStorage.getItem("isLanguageChoosen")) ?? false,
    };
  },

  methods: {
    acceptCookies() {
      this.acceptedCookies = true;
      localStorage.setItem("acceptedCookies", true);
    },

    closeChooseLanguage() {
      this.isLanguageChoosen = true;
      localStorage.setItem("isLanguageChoosen", true);
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.app-container {
  position: relative;
  overflow-x: hidden;
}
</style>
