<template>
  <div>
    <VideoModal v-if="isModalOpened" @close-modal="closeModal" />
    <header class="header" id="home">
      <Menu />

      <div class="contact-us">
        <h1 class="title">{{ $t("header.title") }}</h1>
        <p class="description">{{ $t("header.description") }}</p>
        <form class="contact-us__form">
          <div class="input-wrapper">
            <input
              class="input "
              type="text"
              v-bind:placeholder="$t('header.inputPlaceholder')"
              v-model="email"
            />
            <!-- v-bind:class="{ incorrect: !isCorrectEmail }" -->
            <!-- @input="checkEmail" -->
            <router-link :to="`/contact-us/${email}`">
              <button
                class="get-started"
                type="button"
                @click="trackClick('Click get started btn')"
              >
                {{ $t("header.getStartedBtn") }}
              </button>
            </router-link>
          </div>
        </form>
        <img
          v-lazy="{
            src: require('../../assets/video.png'),
            loading: require('../../assets/video_min.png'),
          }"
          class="video-image"
          alt="video-img"
        />

        <button
          class="play-btn"
          tabindex="-1"
          @click="openModal"
          :class="{ 'play-btn__fr': this.$i18n.locale === 'fr' }"
        ></button>
      </div>
    </header>
  </div>
</template>

<script>
import Menu from "../Menu";
import VideoModal from "../VideoModal";
import { track } from "../../scripts/gtag";

export default {
  components: {
    Menu,
    VideoModal,
  },

  data() {
    return {
      email: "",
      isModalOpened: false,
    };
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    closeModal() {
      this.isModalOpened = false;
    },

    openModal() {
      this.trackClick("Click play video button");
      this.isModalOpened = true;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  overflow-x: hidden;
}

.header {
  position: relative;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  background-image: linear-gradient(
    165.33deg,
    rgba(168, 214, 239, 0.8) 0%,
    rgba(205, 236, 253, 0.8) 27.17%,
    rgba(233, 247, 255, 0.8) 67.19%,
    #f1f9fc 100%
  );

  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: left;
    position: relative;
    z-index: 10;
    margin: 0 auto 323px;
    width: 1128px;

    .title {
      width: 540px;
      margin-bottom: 13px;
      font-size: 48px;
      line-height: 56px;
      color: #2c3e66;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: bold;
    }

    .description {
      width: 530px;
      margin-bottom: 24px;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      font-family: "Montserrat", sans-serif;
      color: #2c3e66;
    }

    .contact-us__form {
      .input-wrapper {
        display: flex;
        justify-content: space-between;
        width: 474px;
        margin-bottom: 14px;

        .input {
          width: 308px;
          background: #f7fcfe;
          border: 2px solid #bcdef0;
          outline: none;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 0px 10px;
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          color: black;
          caret-color: #00c0ff;

          &::placeholder {
            vertical-align: middle;
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            vertical-align: middle;
            color: #a6cbdf;
          }

          &:focus {
            border: 2px solid #00c0ff;
          }
        }

        .get-started {
          background: #bcdef0;
          font-family: "Montserrat", sans-serif;
          text-align: center;
          border-radius: 10px;
          border: 2px solid transparent;
          outline: none;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #2c3e66;
          padding: 11px 20px;
          box-shadow: 0 48px 40px 0px rgba(0, 0, 0, 0.1);
          cursor: pointer;

          text-decoration: none;

          &:hover {
            background: #8dc8e8;
            border: 2px solid #8dc8e8;
          }

          &:focus {
            background: #8dc8e8;
            border: 2px solid #64aad0;
          }
        }
      }
    }

    .video-image {
      position: absolute;
      top: -128px;
      right: -318px;
      width: 801px;
    }

    .play-btn {
      border: none;
      outline: none;
      border-radius: 50%;
      background-color: transparent;
      background-image: url(../../assets/play.png);
      background-position: center;
      background-size: 214px 214px;
      position: absolute;
      top: 90px;
      right: 20px;
      width: 121px;
      height: 121px;
      cursor: pointer;
      backdrop-filter: blur(4px);
      animation: bounce 1.5s infinite alternate;

      &:hover {
        background-image: url(../../assets/play_hover.png);
      }
    }

    @keyframes bounce {
      100% {
        transform: scale(1.1);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .contact-us {
      width: 870px;
      margin: 0 auto 300px;

      .title {
        width: 595px;
      }

      .description {
        width: 585px;
      }

      .contact-us__form {
        .input-wrapper {
          width: 535px;

          .get-started {
            padding: 11px 50px;
          }
        }
      }

      .video-image {
        position: relative;
        top: 204px;
        right: 1px;
        width: 872px;
        height: 649.25px;
      }

      .play-btn {
        top: 709px;
        right: 372px;
      }

      .play-btn__fr {
        top: 788px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .contact-us {
      width: 680px;
      margin: 0 auto 222px;

      .contact-us__form {
        .input-wrapper {
          width: 502px;

          .get-started {
            padding: 11px 25px;
          }

          .input {
            width: 328px;
          }
        }
      }

      .video-image {
        top: 161px;
        right: 0px;
        width: 680px;
        height: 506.18px;
      }

      .play-btn {
        top: 608px;
        right: 287px;

        background-size: 181px 181px;

        width: 101px;
        height: 101px;
      }

      .play-btn__fr {
        top: 688px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .header {
    .contact-us {
      width: 87%;

      .title {
        width: 100%;
      }

      .description {
        width: 100%;
      }

      .contact-us__form {
        .input-wrapper {
          width: 100%;

          .input {
            width: 63%;
          }
        }
      }

      .video-image {
        top: 34px;
        right: 16px;
        width: 502px;
        height: 374px;
      }

      .play-btn {
        top: 511px;
        right: 189px;
        background-size: 151px 151px;
        width: 85px;
        height: 85px;
      }

      .play-btn__fr {
        top: 543px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .header {
    .contact-us {
      .title {
        font-size: 40px;
        line-height: 48px;
      }

      .contact-us__form {
        .input-wrapper {
          flex-direction: column;

          .input {
            width: 100%;
            padding: 10px 25px;

            margin-bottom: 14px;
          }

          .get-started {
            width: 100%;
            padding: 8px 25px;
          }
        }
      }

      .video-image {
        top: 61px;
        right: 16px;
        width: 395px;
        height: 274px;
      }

      .play-btn {
        top: 549px;
        right: 145px;
        background-size: 118px 118px;
        width: 66px;
        height: 66px;
      }

      .play-btn__fr {
        top: 623px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .header {
    .contact-us {
      .video-image {
        top: 61px;
        right: 16px;
        width: 359px;
        height: 267px;
      }

      .play-btn {
        top: 551px;
        right: 128px;
      }

      .play-btn__fr {
        top: 622px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .header {
    .contact-us {
      width: 328px;
      margin: 0 auto 153px;

      .title {
        width: 315px;
        font-size: 40px;
        line-height: 48px;
      }

      .description {
        width: 320px;
      }

      .contact-us__form {
        .input-wrapper {
          flex-direction: column;

          .input {
            width: 100%;
            padding: 12px 10px;
            margin-bottom: 34px;
          }

          .get-started {
            padding: 10px 25px;
            width: 328px;
          }
        }
      }

      .video-image {
        top: 34px;
        right: 16px;
        width: 360px;
        height: 268px;
      }

      .play-btn {
        top: 552px;
        right: 136px;
        background-size: 96px;
        width: 54px;
        height: 54px;
      }

      .play-btn__fr {
        top: 624px;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .header {
    .contact-us {
      .play-btn {
        right: 7px;
      }
    }
  }
}
</style>
