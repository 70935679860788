<template>
  <main class="main">
    <img
      v-lazy="{
        src: require('../../assets/header_bg.png'),
        loading: require('../../assets/header_bg_min.png'),
      }"
      class="header-bg-line"
      :class="{ 'header-bg-line__fr': this.$i18n.locale === 'fr' }"
    />

    <Benefits />

    <Features />

    <FAQ />

    <!-- <Pricing /> -->

    <GotQuestions />
  </main>
</template>

<script>
import Pricing from "./Pricing";
import Benefits from "./Benefits";
import Features from "./Features";
import FAQ from "./FAQ";
import GotQuestions from "./GotQuestions";

export default {
  components: {
    Pricing,
    Benefits,
    Features,
    FAQ,
    GotQuestions,
  },
};
</script>

<style lang="scss">
.main {
  position: relative;
  background: #f1f9fc;
  // overflow-x: hidden;
  overflow-y: visible;

  .header-bg-line {
    z-index: 0;
    top: -733px;
    left: 0.2%;
    position: absolute;
    width: calc((100% - 1139px) / 2 + 1107px);
    height: 952px;
  }

  .header-bg-line__fr {
    top: -790px;
  }
}

@media screen and (max-width: 1024px) {
  .main {
    .header-bg-line {
      z-index: 0;
      top: -982px;
      left: 6px;
      position: absolute;
      width: 1248px;
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    .header-bg-line {
      top: -754px;
      left: -235px;
    }
  }
}

@media screen and (max-width: 360px) {
  .main {
    .header-bg-line {
      top: -626px;
      left: -440px;
    }
  }
}
</style>
