var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"features",attrs:{"id":"features"}},[_c('h2',{staticClass:"features__title"},[_vm._v(_vm._s(_vm.$t("main.features.title")))]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
      src: require('../../assets/features_bg.svg'),
      loading: require('../../assets/features_bg_min.png'),
    }),expression:"{\n      src: require('../../assets/features_bg.svg'),\n      loading: require('../../assets/features_bg_min.png'),\n    }"}],staticClass:"wave"}),_c('section',{staticClass:"card-wrapper"},[_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
          src: require('../../assets/card1.png'),
          loading: require('../../assets/card1_min.png'),
        }),expression:"{\n          src: require('../../assets/card1.png'),\n          loading: require('../../assets/card1_min.png'),\n        }"}],staticClass:"card__img",attrs:{"alt":"card image"}}),_c('h3',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.$t("main.features.cardTitle1")))]),_c('p',{staticClass:"card__description"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardDescription1"))+" ")])]),_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
          src: require('../../assets/card2.png'),
          loading: require('../../assets/card2_min.png'),
        }),expression:"{\n          src: require('../../assets/card2.png'),\n          loading: require('../../assets/card2_min.png'),\n        }"}],staticClass:"card__img",attrs:{"alt":"card image"}}),_c('h3',{staticClass:"card__title card__title2"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardTitle2"))+" ")]),_c('p',{staticClass:"card__description card__description2"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardDescription2"))+" ")])]),_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
          src: require('../../assets/card3.png'),
          loading: require('../../assets/card3_min.png'),
        }),expression:"{\n          src: require('../../assets/card3.png'),\n          loading: require('../../assets/card3_min.png'),\n        }"}],staticClass:"card__img",attrs:{"alt":"card image"}}),_c('h3',{staticClass:"card__title card__title3"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardTitle3"))+" ")]),_c('p',{staticClass:"card__description"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardDescription3"))+" ")])]),_c('div',{staticClass:"card"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
          src: require('../../assets/card4.png'),
          loading: require('../../assets/card4_min.png'),
        }),expression:"{\n          src: require('../../assets/card4.png'),\n          loading: require('../../assets/card4_min.png'),\n        }"}],staticClass:"card__img",attrs:{"alt":"card image"}}),_c('h3',{staticClass:"card__title"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardTitle4"))+" ")]),_c('p',{staticClass:"card__description"},[_vm._v(" "+_vm._s(_vm.$t("main.features.cardDescription4"))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }