<template>
  <div class="menu">
    <Logo url="logo_header.svg" />
    <ul
      class="links-list "
      :class="{ 'links-list__fr': this.$i18n.locale === 'fr' }"
      v-show="pageWidth > 768"
    >
      <li class="item" @click="scroll('benefits')">
        {{ $t("menu.benefits") }}
      </li>
      <li class="item" @click="scroll('features')">
        {{ $t("menu.features") }}
      </li>
      <li class="item" @click="scroll('faq')">{{ $t("menu.faq") }}</li>
      <!-- <li class="item"><router-link to="/blog">Blog</router-link></li> -->
    </ul>
    <a
      href="https://app.b2bprint.pro/login"
      target="_blank"
      rel="noopener noreferrer"
      tabindex="-1"
      v-show="pageWidth > 768"
    >
      <button class="login" @click="trackClick('Click log in button')">
        {{ $t("menu.logIn") }}
      </button>
    </a>

    <div
      class="burger-menu "
      v-show="pageWidth <= 768"
      @click="switchFunc"
      :class="{ opened: isMenuOpened }"
    >
      <ul class="menu-options" v-show="isMenuOpened">
        <li class="menu__item" @click="scroll('home')" v-show="!isOnHomePage">
          {{ $t("footer.firstColumnItem1") }}
        </li>

        <li class="menu__item" @click="scroll('benefits')">
          {{ $t("menu.benefits") }}
        </li>
        <li class="menu__item" @click="scroll('features')">
          {{ $t("menu.features") }}
        </li>
        <li class="menu__item" @click="scroll('faq')">
          {{ $t("menu.faq") }}
        </li>
        <!-- <li class="menu__item"><router-link to="/blog">Blog</router-link></li> -->

        <a
          href="https://app.b2bprint.pro/login"
          target="_blank"
          rel="noopener noreferrer"
          tabindex="-1"
        >
          <button
            class="login login__item"
            @click="trackClick('Click log in button')"
          >
            {{ $t("menu.logIn") }}
          </button>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import { track } from "../scripts/gtag";

export default {
  components: {
    Logo,
  },

  data() {
    return {
      isMenuOpened: false,
      pageWidth: document.body.clientWidth,
      isOnHomePage: this.$route.name === "Home",
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    switchFunc() {
      this.isMenuOpened = !this.isMenuOpened;
    },

    trackClick(str) {
      track.call(this, str);
    },

    scroll(name) {
      this.trackClick(`Click ${name} link`);

      if (this.$route.name === "Home") {
        const el = document.getElementById(name);
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$router.push(`/#${name}`);
      }
    },

    onResize() {
      this.pageWidth = document.body.clientWidth;
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto 158px;
  padding-top: 32px;
  padding-left: 6px;
  width: 1139px;

  .links-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 266px;
    padding: 0;
    margin-left: -103px;
    list-style-type: none;

    .item {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: #2c3e66;
      cursor: pointer;

      &:hover {
        border-bottom: 1px solid #2c3e66;
      }

      a {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .links-list__fr {
    width: 337px;
    margin-left: -43px;
  }

  .login {
    margin-right: 4px;
    padding: 11px 24px;
    border-radius: 10px;
    border: 2px solid #eaf7fd;
    outline: none;
    background-color: #eaf7fd;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #2c3e66;
    cursor: pointer;

    &:hover {
      border: 2px solid #bcdef0;
      background: #bcdef0;
    }

    &:focus {
      background: #bcdef0;
      border: 2px solid #8dc8e8;
    }
  }

  .burger-menu {
    position: relative;
    width: 48px;
    height: 48px;
    margin-right: 6px;
    background-color: #bcdef0;
    border-radius: 10px;
    background-image: url(../assets/menu_lines.svg);
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;

    .menu-options {
      z-index: 100;
      position: absolute;
      width: 328px;
      left: -280px;
      top: 64px;
      padding: 32px;

      background: #ffffff;
      box-shadow: 0px 50px 80px rgba(44, 62, 102, 0.2);
      border-radius: 10px;

      list-style-type: none;
      text-align: center;

      .menu__item {
        margin-bottom: 32px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #2c3e66;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      .login__item {
        width: 100%;
      }
    }
  }

  .opened {
    background-image: url(../assets/menu_cross.svg);
  }
}

@media screen and (max-width: 1024px) {
  .menu {
    width: 882px;
    margin: 0px auto 174px;

    .links-list {
      width: 234px;
    }

    .links-list__fr {
      width: 302px;
    }
  }
}

@media screen and (max-width: 768px) {
  .menu {
    width: 691px;
    margin: 0px auto 134px;
    padding-top: 33px;
  }
}

@media screen and (max-width: 540px) {
  .menu {
    width: 87%;

    .burger-menu {
      margin-right: 0px;
    }
  }
}

@media screen and (max-width: 360px) {
  .menu {
    width: 340px;
    margin: 0px auto 57px;
    padding-top: 11px;

    .burger-menu {
      margin-right: 6px;
    }
  }
}
</style>
