<template>
  <div
    class="question "
    @click="switchActive"
    v-bind:class="{ active: isActive }"
  >
    <h3 class="question-title">{{ question.title }}</h3>
    <p class="answer" v-if="isActive" v-html="question.answer"></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: this.$props.isActive,
      pageWidth: document.body.clientWidth,
    };
  },

  methods: {
    switchActive() {
      this.isActive = !this.isActive;

      if (this.pageWidth > 768) {
        const el = document.getElementById("faq");
        el.scrollIntoView({ behavior: "smooth" });
      }
    },

    hideQuestion(event) {
      if (
        event.target.closest(".question")?.querySelector(".question-title")
          .innerHTML !== this.$props.question.title
      ) {
        this.isActive = false;
      }
    },

    onResize() {
      this.pageWidth = document.body.clientWidth;
    },
  },

  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    document.addEventListener("click", this.hideQuestion);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    document.removeEventListener("click", this.hideQuestion);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
.question {
  position: relative;
  width: 100%;
  margin-bottom: 19px;
  padding: 36px 24px;
  border: 2px solid rgba(166, 203, 223, 0.5);
  border-radius: 10px;
  transition-duration: 0.2s;
  align-self: flex-start;
  cursor: pointer;

  .question-title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #2c3e66;
  }

  &::after {
    content: "";
    width: 7px;
    height: 11.5px;
    position: absolute;
    top: 47px;
    right: 33px;
    transform: translateY(-50%);
    background-image: url(../../assets/arrow.svg);
    background-size: 7px;
    background-position: center;
    background-repeat: no-repeat;
    transition-duration: 0.2s;
  }

  .answer {
    width: 497px;
    padding-top: 28px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #2c3e66;
  }

  .answer__link {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #00c0ff;
  }

  .semi-bold {
    font-weight: 500;
  }

  &:hover {
    border: 2px solid #00c0ff;
  }
}

.active {
  padding-bottom: 24px;

  &::after {
    transform: rotate(90deg);
  }
}
</style>
