<template>
  <div class="cookies">
    <p class="text">
      {{ $t("cookiesText") }}
      <router-link to="/privacy-policy"> {{ $t("cookiesLink") }}</router-link>
    </p>
    <button class="accept-btn" v-on:click="$emit('acceptCookies')">Ok</button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.cookies {
  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 100;
  position: fixed;
  bottom: 24px;
  width: 78%;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px;

  background: #2c3e66;
  border-radius: 10px;
  animation: slideIn 0.8s 1;

  .text {
    width: 73.4%;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    a {
      text-decoration: none;
      color: inherit;
      font-weight: bold;
    }
  }

  .accept-btn {
    padding: 10px 22px;
    background: #eaf7fd;
    border-radius: 10px;
    border: 2px solid #eaf7fd;
    outline: none;
    cursor: pointer;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #2c3e66;

    &:hover {
      background: #bcdef0;
      border: 2px solid #bcdef0;
    }

    &:focus {
      background: #bcdef0;
      border: 2px solid #8dc8e8;
    }
  }

  @keyframes slideIn {
    0% {
      bottom: -200px;
    }

    100% {
      bottom: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .cookies {
    width: 872px;

    .text {
      width: 71.5%;
    }

    .accept-btn {
      padding: 10px 84px;
    }
  }
}

@media screen and (max-width: 768px) {
  .cookies {
    width: 680px;

    .text {
      width: 65%;
    }

    .accept-btn {
      padding: 10px 62px;
    }
  }
}

@media screen and (max-width: 540px) {
  .cookies {
    width: 88%;
    flex-direction: column;

    .text {
      width: 100%;
      margin-bottom: 24px;
    }

    .accept-btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: 360px) {
  .cookies {
    width: 328px;
    flex-direction: column;

    .text {
      width: 100%;
      margin-bottom: 24px;
    }

    .accept-btn {
      width: 100%;
    }
  }
}
</style>
