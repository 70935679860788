<template>
  <div class="v-select">
    <p
      class="title "
      :class="{ 'options-visible': areOptionsVisible }"
      @click="toggleVisibility()"
      :style="inlineBgImage(selected.imageUrl)"
    >
      {{ selected.name }}
    </p>
    <div
      class="options"
      :class="{ options_top: isOpenTop }"
      v-if="areOptionsVisible"
    >
      <p
        class="option"
        v-for="option in options"
        :key="option.value"
        @click="selectOption(option)"
        :style="inlineBgImage(option.imageUrl)"
      >
        {{ option.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    selected: {
      type: Object,
    },
  },

  data() {
    return {
      areOptionsVisible: false,
      pageWidth: document.body.clientWidth,
      isOpenTop:
        document.body.clientWidth <= 1024 && document.body.clientWidth > 768,
    };
  },

  watch: {
    pageWidth: function(width) {
      this.isOpenTop = width <= 1024 && width > 768;
    },
  },

  methods: {
    selectOption(option) {
      this.$emit("select", option);
      this.areOptionsVisible = false;
    },

    toggleVisibility() {
      this.areOptionsVisible = !this.areOptionsVisible;
      this.pageWidth = document.body.clientWidth;
    },

    hideOptions(event) {
      if (!event.target.closest(".v-select")) {
        this.areOptionsVisible = false;
      }
    },

    inlineBgImage(src) {
      let bgImage = require("@/assets" + src);

      return {
        backgroundImage: `url("${bgImage}")`,
      };
    },
  },

  mounted() {
    document.addEventListener("click", this.hideOptions);
  },

  beforeDestroy() {
    document.removeEventListener("click", this.hideOptions);
  },
};
</script>

<style lang="scss">
.v-select {
  margin-bottom: 72px;
  z-index: 99;
  position: relative;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 200px;
  height: 48px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  user-select: none;
  cursor: pointer;

  .title {
    width: 100%;
    padding: 12px 12px 12px 44px;

    background-repeat: no-repeat;
    background-position: 12px 50%;
    background-size: 24px 24px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 14px;
      transform: translateY(-50%);
      transition-duration: 0.3s;

      width: 20px;
      height: 20px;

      background-image: url(../assets/select_arrow.svg);

      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .options-visible {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .options {
    position: absolute;
    z-index: 99;
    top: 62px;
    left: 0;
    width: 100%;
    background: #31405b;
    box-shadow: 0px 50px 80px rgba(44, 62, 102, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    animation: show-options 0.15s 1;

    .option {
      width: 100%;
      padding: 8px;
      padding-left: 44px;

      background-repeat: no-repeat;
      background-position: 12px 50%;
      background-size: 24px 24px;
      border-radius: 8px;
      font-family: inherit;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      &:nth-child(1) {
        margin-bottom: 8px;
      }

      &:hover {
        background: #223255;
        background-repeat: no-repeat;
        background-position: 12px 50%;
        background-size: 24px 24px;
      }
    }
  }

  .options_top {
    top: -124px !important;
  }

  @keyframes show-options {
    0% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }
}
</style>
