import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueGtag from "vue-gtag";
import VueLazyload from "vue-lazyload";
import VueYouTubeEmbed from "vue-youtube-embed";

Vue.use(VueYouTubeEmbed);

Vue.use(VueLazyload);

Vue.use(VueGtag, {
  config: { id: "G-8F4264D15G" },
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
