<template>
  <section class="got-questions">
    <div class="wrapper">
      <h2 class="got-questions__title">{{ $t("main.gotQuestions.title") }}</h2>
      <p
        class="got-questions__text"
        :class="{ 'got-questions__text__fr': this.$i18n.locale === 'fr' }"
      >
        {{ $t("main.gotQuestions.description") }}
      </p>
      <div class="btn-wrapper">
        <!-- <button class="watch-demo-btn">{{ $t("main.gotQuestions.watchDemoBtn") }}</button> -->
        <router-link to="/contact-us" tabindex="-1">
          <button
            class="contact-sales-btn"
            @click="trackClick('Click contact sales button')"
          >
            {{ $t("main.gotQuestions.contactSalesBtn") }}
          </button>
        </router-link>
      </div>
      <img
        v-lazy="{
          src: require('../../assets/got_questions_img.png'),
          loading: require('../../assets/got_questions_img_min.png'),
        }"
        alt="people"
        class="got-questions__img"
      />
    </div>
  </section>
</template>

<script>
import { track } from "../../scripts/gtag";

export default {
  methods: {
    trackClick(str) {
      track.call(this, str);
    },
  },
};
</script>

<style lang="scss">
.got-questions {
  padding: 112px 0 146px;
  background: linear-gradient(
    135deg,
    #83d2ff 0%,
    rgba(187, 219, 237, 0.42) 100%
  );

  .wrapper {
    position: relative;
    width: 1128px;
    margin: 0 auto;

    .got-questions__title {
      width: 40%;
      margin-bottom: 12px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #2c3e66;
    }

    .got-questions__text {
      width: 32%;
      margin-bottom: 34px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #2c3e66;
    }

    .got-questions__text__fr {
      width: 36%;
    }

    .btn-wrapper {
      display: flex;
      justify-content: space-between;
      width: 25%;

      .watch-demo-btn {
        padding: 12px 25px;
        background: #2c3e66;
        border-radius: 10px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        border: transparent;
        outline: none;
        cursor: pointer;
      }

      .contact-sales-btn {
        padding: 12px 30px;
        background: rgba(234, 247, 253, 0.5);
        border-radius: 10px;
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #2c3e66;
        border: 2px solid transparent;
        outline: none;
        cursor: pointer;

        &:hover {
          background: #2c3e66;
          border: 2px solid #2c3e66;
          color: #ffffff;
        }

        &:focus {
          background: #2c3e66;
          border: 2px solid #2c3e66;
          color: #ffffff;
        }
      }
    }

    .got-questions__img {
      position: absolute;
      top: -41px;
      right: -3px;
      width: 607px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .got-questions {
    .wrapper {
      width: 871px;

      .got-questions__title {
        width: 50%;
      }

      .got-questions__text {
        width: 39%;
      }

      .got-questions__img {
        top: 9px;
        right: -99px;
        width: 546px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .got-questions {
    padding: 112px 0 206px;

    .wrapper {
      width: 680px;

      .got-questions__title {
        width: 60%;
      }

      .got-questions__text {
        width: 55%;
      }

      .btn-wrapper {
        width: 40%;

        .contact-sales-btn {
          padding: 12px 61px;
        }
      }

      .got-questions__img {
        top: 76px;
        right: -128px;
        width: 480px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .got-questions {
    padding: 112px 0 341px;
    min-height: 700px;

    .wrapper {
      width: 87%;

      .got-questions__title {
        width: 100%;
      }

      .got-questions__text {
        width: 81%;
      }

      .btn-wrapper {
        width: 100%;
      }

      .got-questions__img {
        top: 316px;
        right: -10px;
        width: 480px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .got-questions {
    .wrapper {
      .got-questions__text {
        width: 100%;
      }

      .got-questions__img {
        right: -84px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .got-questions {
    min-height: 750px;

    .wrapper {
      .got-questions__img {
        top: 369px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .got-questions {
    padding: 112px 0 400px;

    .wrapper {
      width: 328px;

      .got-questions__title {
        width: 100%;
      }

      .got-questions__text {
        width: 100%;
      }

      .btn-wrapper {
        width: 100%;

        .contact-sales-btn {
          width: 329px;
        }
      }

      .got-questions__img {
        top: 394px;
        right: -103px;
        width: 480px;
      }
    }
  }
}
</style>
