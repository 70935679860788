<template>
  <div class="choose-language">
    <p class="title">Choose language</p>
    <button class="en-btn btn" @click="setEnglish">
      <span class="full-text">English</span><span class="short-text">EN</span>
    </button>
    <button class="fr-btn btn" @click="setFrench">
      <span class="full-text">French</span><span class="short-text">FR</span>
    </button>
    <img
      src="../assets/close.svg"
      alt="close"
      class="close-btn"
      @click="$emit('closed')"
    />
  </div>
</template>

<script>
import { track } from "../scripts/gtag";

export default {
  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    setEnglish() {
      this.trackClick("Choose English language");

      this.$i18n.locale = "en";
      localStorage.setItem(
        "language",
        JSON.stringify({ name: "English", imageUrl: "/UK.png", value: "en" })
      );
      this.$emit("closed");
    },

    setFrench() {
      this.trackClick("Choose French language");

      this.$i18n.locale = "fr";
      localStorage.setItem(
        "language",
        JSON.stringify({ name: "French", imageUrl: "/France.png", value: "fr" })
      );
      this.$emit("closed");
    },
  },
};
</script>

<style lang="scss">
.choose-language {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #8dc8e8;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  .title {
    padding: 12px 24px;
  }

  .en-btn {
    background-image: url(../assets/UK.png);
  }

  .fr-btn {
    background-image: url(../assets/France.png);
  }

  .btn {
    padding: 12px 24px 12px 60px;
    border: none;
    outline: none;
    background-color: #8dc8e8;
    background-repeat: no-repeat;
    background-position: 26px 50%;
    background-size: 24px 24px;
    color: inherit;
    font: inherit;
    line-height: inherit;
    cursor: pointer;

    &:hover {
      background-color: #64aad0;
    }

    .short-text {
      display: none;
      font: inherit;
    }
  }

  .close-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .choose-language {
    .title {
      padding-left: 75px;
    }

    .close-btn {
      right: 77px;
    }
  }
}

@media screen and (max-width: 768px) {
  .choose-language {
    .title {
      padding-left: 45px;
    }

    .close-btn {
      right: 49px;
    }
  }
}

@media screen and (max-width: 540px) {
  .choose-language {
    .btn {
      padding: 12px 20px 12px 49px;
      background-position: 18px 50%;
    }

    .close-btn {
      right: 38px;
    }
  }
}

@media screen and (max-width: 414px) {
  .choose-language {
    .title {
      padding-left: 16px;
      padding-right: 37px;
    }

    .btn {
      padding: 12px 22px 12px 38px;
      background-position: 8px 50%;

      .full-text {
        display: none;
      }

      .short-text {
        display: block;
      }
    }

    .close-btn {
      right: 22px;
    }
  }
}

@media screen and (max-width: 414px) {
  .choose-language {
    .title {
      padding-right: 16px;
    }

    .btn {
      padding: 12px 16px 12px 38px;
    }
  }
}

@media screen and (max-width: 360px) {
  .choose-language {
    .title {
      padding-left: 16px;
      padding-right: 16px;
    }

    .btn {
      padding: 12px 13px 12px 38px;
      background-position: 8px 50%;

      .full-text {
        display: none;
      }

      .short-text {
        display: block;
      }
    }

    .close-btn {
      right: 15px;
    }
  }
}
</style>
