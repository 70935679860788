<template>
  <div class="modal" @click="close">
    <youtube
      :video-id="videoSrc"
      player-width="1150"
      player-height="650"
      @ready="ready"
      :player-vars="{ autoplay: 1 }"
      class="video"
    ></youtube>
    <button class="close-btn" type="button" @click="close">
      &times;
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoSrc: this.$i18n.locale === "en" ? "kjhSHzYPkLQ" : "CvUJOqYPz4Q",
    };
  },

  methods: {
    ready(event) {
      this.player = event.target;
    },

    close() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);

  .close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    padding: 0;
    cursor: pointer;
    color: #fff;
    font-size: 35px;
    border: none;
    background: none;
    outline: none;
  }

  .video iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    .video iframe {
      width: 800px;
      height: 450px;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    .video iframe {
      width: 600px;
      height: 338px;
    }
  }
}

@media screen and (max-width: 540px) {
  .modal {
    .video iframe {
      width: 500px;
      height: 281px;
    }
  }
}

@media screen and (max-width: 414px) {
  .modal {
    .video iframe {
      width: 400px;
      height: 225px;
    }
  }
}

@media screen and (max-width: 375px) {
  .modal {
    .video iframe {
      width: 358px;
      height: 200px;
    }
  }
}
</style>
