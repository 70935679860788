<template>
  <section class="features" id="features">
    <h2 class="features__title">{{ $t("main.features.title") }}</h2>

    <img
      v-lazy="{
        src: require('../../assets/features_bg.svg'),
        loading: require('../../assets/features_bg_min.png'),
      }"
      class="wave"
    />

    <section class="card-wrapper">
      <div class="card">
        <img
          v-lazy="{
            src: require('../../assets/card1.png'),
            loading: require('../../assets/card1_min.png'),
          }"
          alt="card image"
          class="card__img"
        />
        <h3 class="card__title">{{ $t("main.features.cardTitle1") }}</h3>
        <p class="card__description">
          {{ $t("main.features.cardDescription1") }}
        </p>
      </div>

      <div class="card">
        <img
          v-lazy="{
            src: require('../../assets/card2.png'),
            loading: require('../../assets/card2_min.png'),
          }"
          alt="card image"
          class="card__img"
        />
        <h3 class="card__title card__title2">
          {{ $t("main.features.cardTitle2") }}
        </h3>
        <p class="card__description card__description2">
          {{ $t("main.features.cardDescription2") }}
        </p>
      </div>

      <div class="card">
        <img
          v-lazy="{
            src: require('../../assets/card3.png'),
            loading: require('../../assets/card3_min.png'),
          }"
          alt="card image"
          class="card__img"
        />
        <h3 class="card__title card__title3">
          {{ $t("main.features.cardTitle3") }}
        </h3>
        <p class="card__description">
          {{ $t("main.features.cardDescription3") }}
        </p>
      </div>

      <div class="card">
        <img
          v-lazy="{
            src: require('../../assets/card4.png'),
            loading: require('../../assets/card4_min.png'),
          }"
          alt="card image"
          class="card__img"
        />
        <h3 class="card__title">
          {{ $t("main.features.cardTitle4") }}
        </h3>
        <p class="card__description">
          {{ $t("main.features.cardDescription4") }}
        </p>
      </div>
    </section>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.features {
  position: relative;
  padding: 106px 0 167px;
  background: linear-gradient(116.29deg, #2c3e66 9.96%, #1a2948 84.99%);
  box-shadow: inset 0px 50px 80px rgba(6, 12, 26, 0.2);

  .features__title {
    margin-bottom: 80px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
  }

  .wave {
    position: absolute;
    width: 100%;
    top: 187px;
    left: 0;
    z-index: 0;
  }

  .card-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1128px;

    .card {
      width: 210px;
      position: relative;
      z-index: 1;

      .card__img {
        margin-bottom: 19px;
        width: 210px;
        height: 210px;
      }

      .card__title {
        margin-bottom: 12px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }

      .card__description {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .features {
    padding: 121px 0 277px;

    .card-wrapper {
      width: 749px;
      flex-wrap: wrap;

      .card {
        width: 312px;

        .card__img {
          width: 200px;
          height: 200px;
        }

        .card__title2 {
          width: 180px;
        }

        .card__title3 {
          width: 215px;
        }

        .card__description2 {
          margin-bottom: 80px;
        }
      }
    }

    .wave {
      width: 1442px;
      top: 167px;
      left: -209px;
    }
  }
}

@media screen and (max-width: 768px) {
  .features {
    padding: 106px 0 126px;

    .card-wrapper {
      width: 610px;

      .card {
        width: 240px;
        margin-left: 29px;

        .card__img {
          width: 104px;
          height: 104px;
        }

        .card__title2 {
          width: 240px;
        }

        .card__title3 {
          width: 240px;
        }

        .card__description2 {
          margin-bottom: 48px;
        }
      }
    }

    .wave {
      width: 1442px;
      top: 216px;
      left: -338px;
    }
  }
}

@media screen and (max-width: 540px) {
  .features {
    .card-wrapper {
      width: 87%;

      .card {
        width: 60%;
        margin-bottom: 40px;
        margin-left: 50px;

        .card__img {
          width: 134px;
          height: 134px;
        }

        .card__title2 {
          width: 240px;
        }

        .card__title3 {
          width: 240px;
        }

        .card__description2 {
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .features {
    .card-wrapper {
      .card {
        width: 70%;
        margin-bottom: 40px;
        margin-left: 40px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .features {
    .card-wrapper {
      .card {
        .card__img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .features {
    padding: 106px 0 57px;

    .card-wrapper {
      width: 330px;

      .card {
        width: 270px;
        margin-left: 29px;
        margin-bottom: 0;

        .card__img {
          width: 104px;
          height: 104px;
        }

        .card__title2 {
          width: 270px;
        }

        .card__title3 {
          width: 240px;
        }

        .card__description2 {
          margin-bottom: 32px;
        }

        .card__description {
          margin-bottom: 32px;
        }
      }
    }

    .wave {
      top: 137px;
      left: -541px;
    }
  }
}
</style>
