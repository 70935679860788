<template>
  <section class="benefits">
    <div id="benefits" class="benefits-anchor"></div>
    <button class="section-name" tabindex="-1">
      {{ $t("main.benefits.title1") }}
    </button>
    <h2 class="section-title">{{ $t("main.benefits.title2") }}</h2>

    <section class="save-time">
      <img
        v-lazy="{
          src: require('../../assets/save_time_img.png'),
          loading: require('../../assets/save_time_img_min.png'),
        }"
        alt="save time"
        class="save-time__img"
      />
      <div class="save-time-wrapper">
        <h3 class="list-title">{{ $t("main.benefits.listTitle1") }}</h3>
        <ul class="list">
          <li class="list__item">{{ $t("main.benefits.firstListItem1") }}</li>
          <li class="list__item">{{ $t("main.benefits.firstListItem2") }}</li>
          <li class="list__item">{{ $t("main.benefits.firstListItem3") }}</li>
        </ul>
      </div>
    </section>

    <section class="control-manage">
      <div
        class="control-manage-wrapper"
        :class="{ 'control-manage-wrapper__fr': this.$i18n.locale === 'fr' }"
      >
        <h3 class="list-title">{{ $t("main.benefits.listTitle2") }}</h3>
        <ul class="list">
          <li class="list__item">{{ $t("main.benefits.secondListItem1") }}</li>
          <li class="list__item">{{ $t("main.benefits.secondListItem2") }}</li>
          <li class="list__item">{{ $t("main.benefits.secondListItem3") }}</li>
          <li class="list__item">{{ $t("main.benefits.secondListItem4") }}</li>
        </ul>
      </div>
      <img
        v-lazy="{
          src: require('../../assets/control_manage_img.png'),
          loading: require('../../assets/control_manage_img_min.png'),
        }"
        alt="control and manage"
        class="control-manage__img"
      />
    </section>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.benefits {
  width: 1128px;
  margin: 0 auto;
  padding-bottom: 143px;

  .benefits-anchor {
    position: relative;
    top: -90px;
    width: 18px;
    height: 1px;
    background-color: transparent;
  }

  .section-name {
    padding: 2px 20px;
    margin-bottom: 32px;
    border-radius: 40px;
    border: none;
    background: #bcdef0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ffff;
    pointer-events: none;
  }

  .section-title {
    width: 419px;
    margin-bottom: 117px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #2c3e66;
  }

  .save-time {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 160px;
    position: relative;
    width: 1131px;

    .save-time__img {
      position: absolute;
      top: -21px;
      left: -21px;
      width: 542px;
    }
  }

  .control-manage {
    display: flex;
    justify-content: flex-start;
    position: relative;

    .control-manage__img {
      position: absolute;
      top: -86px;
      right: -323px;
      width: 796px;
    }
  }

  .list-title {
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #2c3e66;
  }

  .list {
    list-style-image: url("../../assets/list_marker.svg");
    padding: 0px;
    padding-left: 27px;

    .list__item {
      padding-left: 10px;
      margin-bottom: 32px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #2c3e66;
    }
  }

  .save-time-wrapper {
    width: 520px;
  }

  .control-manage-wrapper {
    width: 500px;
  }

  .control-manage-wrapper__fr {
    width: 508px;
  }
}

@media screen and (max-width: 1024px) {
  .benefits {
    width: 870px;
    padding-bottom: 188px;

    .section-title {
      margin-bottom: 113px;
    }

    .save-time {
      width: 863px;
      margin-bottom: 85px;

      .save-time__img {
        top: 0px;
        left: 0px;
        width: 385px;
      }
    }

    .save-time-wrapper {
      width: 416px;
    }

    .control-manage {
      .control-manage__img {
        top: 27px;
        right: -197px;
        width: 575px;
      }
    }

    .control-manage-wrapper {
      width: 440px;
    }
  }
}

@media screen and (max-width: 768px) {
  .benefits {
    width: 680px;
    padding-bottom: 87px;

    .section-title {
      margin-bottom: 484px;
    }

    .save-time {
      justify-content: flex-start;
      margin-bottom: 565px;

      .save-time__img {
        top: -403px;
        left: 72px;
        width: 541px;
        height: 385px;
      }
    }

    .save-time-wrapper {
      width: 655px;
    }

    .control-manage {
      .control-manage__img {
        top: -489px;
        right: -211px;
        width: 795px;
      }
    }

    .control-manage-wrapper {
      width: 680px;
    }
  }
}

@media screen and (max-width: 540px) {
  .benefits {
    width: 87%;

    .save-time {
      width: 100%;
      margin: 0 auto 430px;

      .save-time__img {
        top: -403px;
        left: 10px;
        width: 446px;
        height: 318px;
      }
    }

    .save-time-wrapper {
      width: 100%;
    }

    .control-manage {
      width: 100%;

      .control-manage__img {
        top: -462px;
        right: -190px;
        width: 647px;
      }
    }

    .control-manage-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 414px) {
  .benefits {
    .section-title {
      margin-bottom: 425px;
    }

    .save-time {
      .save-time__img {
        top: -355px;
        left: 9px;
        width: 367px;
        height: 262px;
      }
    }

    .control-manage {
      .control-manage__img {
        top: -399px;
        right: -178px;
        width: 534px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .benefits {
    .section-title {
      width: 100%;
    }

    .save-time {
      .save-time__img {
        top: -355px;
        left: -7px;
        width: 356px;
        height: 254px;
      }
    }

    .control-manage {
      .control-manage__img {
        top: -379px;
        right: -165px;
        width: 493px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .benefits {
    width: 327px;
    padding-bottom: 87px;

    .section-name {
      margin-bottom: 20px;
    }

    .section-title {
      margin-bottom: 338px;
      width: 251px;
    }

    .save-time {
      margin-bottom: 316px;

      .save-time__img {
        top: -257px;
        left: 0px;
        width: 330px;
        height: 236px;
      }
    }

    .save-time-wrapper {
      width: 325px;
    }

    .control-manage {
      .control-manage__img {
        top: -269px;
        right: -103px;
        width: 415px;
      }
    }

    .control-manage-wrapper {
      width: 680px;
    }
  }
}
</style>
