<template>
  <div class="post-component">
    <Menu />
    <div class="post-wrapper">
      <div class="post-header">
        <span class="post-header__blog">Blog</span>
        <span class="post-header__text">
          {{ $t(`blog.posts[${postIndex}].title`) }}
        </span>
      </div>
      <div class="post-image__wrapper">
        <img
          :src="
            require(`@/assets/${this.$t(`blog.posts[${postIndex}].imageUrl`)}`)
          "
          class="post__image"
          alt="img"
        />
        <button class="post__category">
          {{ $t(`blog.posts[${postIndex}].category`) }}
        </button>
      </div>
      <div class="post-content">
        <h2 class="post-title">
          {{ $t(`blog.posts[${postIndex}].title`) }}
        </h2>
        <p
          class="post-text"
          v-html="this.$t(`blog.posts[${postIndex}].fullText`)"
        ></p>
      </div>
      <router-link to="/blog" class="route">
        <p class="back-to-blog-btn">{{ $t(`blog.backToBlog`) }}</p>
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Menu from "../Menu";
import Footer from "../Home/Footer";

export default {
  components: {
    Menu,
    Footer,
  },

  data() {
    return {
      postIndex: this.$route.params.index,
    };
  },
};
</script>

<style lang="scss">
.post-component {
  background: linear-gradient(
    165.33deg,
    rgba(168, 214, 239, 0.8) 0%,
    rgba(205, 236, 253, 0.8) 27.17%,
    rgba(233, 247, 255, 0.8) 67.19%,
    rgba(241, 247, 250, 0.8) 100%
  );
  font-family: "Montserrat", sans-serif;

  .menu {
    margin: 0px auto 70px;
  }

  .post-wrapper {
    width: 51.7%;
    max-width: 744px;
    margin: 0 auto;
    margin-bottom: 79px;

    .post-header {
      margin-bottom: 32px;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #a6cbdf;

      .post-header__blog {
        position: relative;
        padding-right: 29px;
        font-weight: 500;

        &::after {
          position: absolute;
          top: -3px;
          right: 0px;
          content: "";
          width: 24px;
          height: 24px;
          background-image: url(../../assets/post_component_arrow.svg);
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }

    .post-image__wrapper {
      position: relative;
      width: 100%;
      margin-bottom: 27px;

      .post__image {
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }

      .post__category {
        position: absolute;
        bottom: 29px;
        right: 25px;
        padding: 2px 20px;
        background: rgba(188, 222, 240, 0.8);
        border: none;
        outline: none;
        border-radius: 40px;

        font-family: inherit;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }
    }

    .post-content {
      margin-bottom: 48px;

      .post-title {
        margin-bottom: 25px;
        font-size: 32px;
        line-height: 48px;
        color: #2c3e66;
      }

      .post-text {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #2c3e66;
      }

      .text-link {
        color: #00c0ff;
      }

      .text-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #2c3e66;
      }
    }

    .route {
      text-decoration: none;
      color: #2c3e66;
    }

    .back-to-blog-btn {
      position: relative;
      padding-left: 36px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #2c3e66;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 0px;
        left: 0px;
        transform: rotate(180deg);
        content: "";
        width: 24px;
        height: 24px;
        background-image: url(../../assets/post_arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        color: #00c0ff;
      }

      &:hover:before {
        background-image: url(../../assets/post_arrow_hover.svg);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .post-component {
    .post-wrapper {
      width: 61.7%;
    }
  }
}

@media screen and (max-width: 768px) {
  .post-component {
    .post-wrapper {
      width: 87.7%;
    }
  }
}
</style>
