<template>
  <div class="contact-us-component">
    <Menu />

    <img
      v-lazy="{
        src: require('../../assets/contact_us_bg.png'),
        loading: require('../../assets/contact_us_bg_min.png'),
      }"
      class="contact-us__bg"
    />

    <img
      v-lazy="{
        src: require('../../assets/contact_us_img.png'),
        loading: require('../../assets/contact_us_img_min.png'),
      }"
      class="contact-us__img"
    />

    <form class="form" @submit.prevent="sendEmail" v-if="!isSended">
      <h1 class="form-title">{{ $t("contactUs.title") }}</h1>
      <p class="form-desc">{{ $t("contactUs.description") }}</p>
      <label for="name" class="label">{{ $t("contactUs.label1") }}</label>
      <input
        type="text"
        id="name"
        class="input "
        :class="{ incorrect: !isCorrectName }"
        :placeholder="$t('contactUs.placeholder1')"
        v-model="name"
        @input="checkName"
      />
      <label for="email" class="label">{{ $t("contactUs.label2") }}</label>
      <input
        type="text"
        id="email"
        class="input "
        :class="{ incorrect: !isCorrectEmail }"
        :placeholder="$t('contactUs.placeholder2')"
        v-model="email"
        @input="checkEmail"
      />
      <label for="message" class="label">{{ $t("contactUs.label3") }}</label>
      <textarea
        id="message"
        rows="4"
        class="input"
        :class="{ incorrect: !isCorrectMessage }"
        :placeholder="$t('contactUs.placeholder3')"
        v-model="message"
        @input="checkMessage"
      ></textarea>
      <div class="agreement-wrapper">
        <input type="checkbox" id="agreement" v-model="isAgree" />
        <label for="agreement" class="agreement-label form-desc">
          {{ $t("contactUs.label4") }}
        </label>
      </div>
      <p class="error-message " :class="{ visible: error }">{{ error }}</p>
      <button type="submit" class="submit-btn label">
        {{ $t("contactUs.sendBtn") }}
      </button>
      <p class="form-desc">
        {{ $t("contactUs.privacyPolicy1") }}
        <router-link to="/privacy-policy">
          {{ $t("contactUs.privacyPolicy2") }}
        </router-link>
      </p>
    </form>

    <div class="sended-wrapper" v-else>
      <img src="../../assets/sended.png" alt="sended" />
      <h2 class="form-title">{{ $t("contactUs.sended.title") }}</h2>
      <p class="text">{{ $t("contactUs.sended.description") }}</p>
      <router-link to="/" tabindex="-1">
        <button class="back-to-home">
          {{ $t("contactUs.sended.backHomeBtn") }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
import Menu from "../Menu";
import { track } from "../../scripts/gtag";

import {
  validateEmail,
  validateName,
  validateMessage,
} from "../../scripts/validations";

export default {
  components: {
    Menu,
  },

  data() {
    return {
      name: this.$route.params.name ?? "",
      email: this.$route.params.email ?? "",
      message: "",
      error: "",
      isAgree: false,
      isSended: false,
      isCorrectEmail: true,
      isCorrectName: true,
      isCorrectMessage: true,
    };
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    checkName() {
      if (validateName(this.name)) {
        this.isCorrectName = true;
        this.error = "";
      }
    },

    checkEmail() {
      if (validateEmail(this.email)) {
        this.isCorrectEmail = true;
        this.error = "";
      }
    },

    checkMessage() {
      if (validateMessage(this.message)) {
        this.isCorrectMessage = true;
        this.error = "";
      }
    },

    async sendEmail() {
      this.trackClick("click send email button");

      if (
        validateName(this.name) &&
        validateEmail(this.email) &&
        validateMessage(this.message) &&
        this.isAgree
      ) {
        fetch("https://app.b2bprint.pro/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            sendToEmail: "contact@b2bprint.pro",
            // sendToEmail: "3glifeyy@gmail.com", // email for test
            email: this.email,
            message: this.message,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.isSended = true;
          })
          .catch((err) => {
            this.error = "Somthing went wrong. Try to send a bit later";
          });
      } else {
        this.isCorrectName = validateName(this.name);
        this.isCorrectEmail = validateEmail(this.email);
        this.isCorrectMessage = validateMessage(this.message);
        this.error = "Error! Check the entered data";

        if (!this.isAgree) {
          this.error = "You should accept our Privacy Policy";
        }
      }
    },
  },
};
</script>

<style lang="scss">
.contact-us-component {
  position: relative;
  padding-bottom: 53px;
  background: linear-gradient(
    170.33deg,
    rgba(168, 214, 239, 0.8) 0%,
    rgba(205, 236, 253, 0.8) 27.17%,
    rgba(233, 247, 255, 0.8) 67.19%,
    rgba(241, 247, 250, 0.8) 100%
  );
  min-height: 100vh;

  .menu {
    margin: 0px auto 66px;
  }

  .error-message {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 24px;
    font-family: "Montserrat", sans-serif;
    color: red;
    visibility: hidden;
    height: 16px;
    min-width: 16px;
  }
  .visible {
    visibility: visible;
  }

  .contact-us__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 454px;
    height: 924px;
    min-height: 100vh;
  }

  .contact-us__img {
    position: absolute;
    top: 299px;
    left: 48px;
    width: 360px;
    height: 325px;
  }

  .form-title {
    margin-bottom: 8px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #2c3e66;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 585px;
    margin: 0 auto;
    padding-left: 143px;

    .form-desc {
      margin-bottom: 24px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #a6cbdf;

      a {
        color: inherit;
      }
    }

    .label {
      margin-bottom: 6px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #2c3e66;
    }

    .input {
      width: 100%;
      padding: 12px;
      margin-bottom: 34px;
      background: #f7fcfe !important;
      border: 2px solid #bcdef0;
      border-radius: 10px;
      resize: none;
      outline: none;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      color: black;
      caret-color: #00c0ff;

      &::placeholder {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        vertical-align: middle;
        color: #a6cbdf;
      }

      &:focus {
        border: 2px solid #00c0ff;
      }
    }

    .input:-webkit-autofill,
    .input:-webkit-autofill:hover,
    .input:-webkit-autofill:focus,
    .input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f7fcfe inset !important;
    }

    #message {
      margin-bottom: 14px;
    }

    .submit-btn {
      padding: 12px 25px;
      margin-bottom: 24px;

      outline: none;
      background: #bcdef0;
      border-radius: 10px;
      cursor: pointer;
    }

    .agreement-wrapper {
      display: flex;
      padding-left: 20px;

      #agreement {
        visibility: hidden;
      }

      .agreement-label {
        position: relative;
        margin-bottom: 10px;

        cursor: pointer;

        &:before {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: -1px;
          left: -33px;
          content: "";
          border: 1px solid #bcdef0;
          font-size: 20px;
          font-weight: bold;
          background: #ffffff;
          border-radius: 5px;
          width: 20px;
          height: 20px;
          cursor: pointer;
          transition-duration: 0.1s;
        }
      }

      #agreement:checked + .agreement-label:before {
        // content: "\2714";
        // color: #ffffff;
        // background: #bcdef0;
        content: "";
        background-color: #2c3e66;
        border-color: #2c3e66;
        background-image: url(../../assets/checkbox_marker.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 10px;
      }
    }
  }

  .sended-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 585px;
    margin: 0 auto;
    padding-left: 143px;

    img {
      margin-bottom: 38px;
      width: 128px;
    }

    .form-title {
      margin-bottom: 13px;
    }

    .text {
      margin-bottom: 61px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #a6cbdf;
    }

    .back-to-home {
      padding: 12px 25px;
      background: #bcdef0;
      border-radius: 10px;

      outline: none;
      cursor: pointer;

      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #2c3e66;
    }
  }

  .submit-btn,
  .back-to-home {
    border: 2px solid #bcdef0;

    &:hover {
      border: 2px solid#8DC8E8;
      background: #8dc8e8;
    }

    &:focus {
      background: #8dc8e8;
      border: 2px solid #64aad0;
    }
  }
}
.incorrect {
  border: 2px solid #ff2800 !important;

  &:focus {
    border: 2px solid #ff2800 !important;
  }
}

@media screen and (max-width: 1024px) {
  .contact-us-component {
    .menu {
      margin: 0px auto 88px;
    }

    .form {
      width: 440px;
      margin: 0 auto;
      padding-left: 0px;

      .submit-btn {
        padding: 12px 35px;
      }
    }

    .contact-us__bg,
    .contact-us__img {
      visibility: hidden;
      height: 0;
    }

    .sended-wrapper {
      align-items: center;
      padding-left: 0px;
    }
  }
}

@media screen and (max-width: 540px) {
  .contact-us-component {
    .sended-wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 414px) {
  .contact-us-component {
    .form {
      width: 87%;

      .submit-btn {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .contact-us-component {
    .menu {
      margin: 0px auto 37px;
    }

    .form {
      width: 328px;

      .submit-btn {
        width: 328px;
      }
    }

    .contact-us__bg,
    .contact-us__img {
      visibility: hidden;
      height: 0;
    }

    .sended-wrapper {
      width: 328px;

      .back-to-home {
        width: 328px;
      }
    }
  }
}
</style>
