<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="logo-copyright-wrapper">
        <Logo url="logo_footer.svg" />

        <p class="copyright">{{ $t("footer.copyright") }}</p>
      </div>
      <div
        class="streamline-sales-wrapper"
        :class="{ 'streamline-sales-wrapper__fr': this.$i18n.locale === 'fr' }"
      >
        <h3 class="title">{{ $t("footer.columnTitle1") }}</h3>
        <ul class="links-list">
          <li class="item small-text" @click="scroll('home')">
            {{ $t("footer.firstColumnItem1") }}
          </li>
          <li class="item small-text" @click="scroll('benefits')">
            {{ $t("footer.firstColumnItem2") }}
          </li>
          <li class="item small-text" @click="scroll('features')">
            {{ $t("footer.firstColumnItem3") }}
          </li>
          <li class="item small-text" @click="scroll('faq')">
            {{ $t("footer.firstColumnItem4") }}
          </li>
        </ul>
      </div>
      <div
        class="contact-wrapper"
        :class="{ 'contact-wrapper__fr': this.$i18n.locale === 'fr' }"
      >
        <h3 class="title">{{ $t("footer.columnTitle2") }}</h3>
        <p class="small-text">
          <a
            href="mailto:contact@b2bprint.pro"
            @click="trackClick('Click email link')"
          >
            {{ $t("footer.secondColumnItem1") }}
          </a>
        </p>
        <!-- <router-link to="/contact-us"> -->
        <!-- <p class="small-text mb-57">{{ $t("footer.secondColumnItem2") }}</p> -->
        <p class="small-text mb-57">
          <a
            href="tel:+33173436190"
            @click="trackClick('Click phone number link')"
            >+33 1 73 43 61 90</a
          >
        </p>
        <!-- </router-link> -->
        <h3 class="title">{{ $t("footer.columnTitle3") }}</h3>
        <p
          class="small-text mb-32"
          @click="trackClick('Click Privacy policy link')"
        >
          <router-link to="/privacy-policy">
            {{ $t("footer.thirdColumnItem1") }}
          </router-link>
        </p>
      </div>
      <div class="social-media-wrapper">
        <div class="select-wrapper">
          <VSelect
            :options="options"
            :selected="selected"
            @select="optionSelect"
          />
        </div>
        <h3 class="title title-follow">{{ $t("footer.socialMediaTitle") }}</h3>
        <ul class="social-media-list">
          <a
            href="https://www.facebook.com/b2bprintpro"
            target="_blank"
            @click="trackClick('Click facebook link')"
          >
            <li class="item facebook-icon"></li>
          </a>
          <a
            href="https://www.linkedin.com/company/b2b-print-pro/?viewAsMember=true"
            target="_blank"
            @click="trackClick('Click linkedin link')"
          >
            <li class="item linkedin-icon"></li>
          </a>
          <a
            href="https://twitter.com/print_b2b"
            target="_blank"
            @click="trackClick('Click twitter link')"
          >
            <li class="item twitter-icon"></li>
          </a>
          <a
            href="https://www.youtube.com/channel/UCF-f-Yc7lbqNDXqj85jKHww"
            target="_blank"
            @click="trackClick('Click youtube link')"
          >
            <li class="item youtube-icon"></li>
          </a>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from "../Logo";
import VSelect from "../VSelect";
import { track } from "../../scripts/gtag";

export default {
  components: {
    Logo,
    VSelect,
  },

  data() {
    return {
      options: [
        { name: "English", imageUrl: "/UK.png", value: "en" },
        { name: "French", imageUrl: "/France.png", value: "fr" },
      ],

      selected: JSON.parse(localStorage.getItem("language")) ?? {
        name: "English",
        imageUrl: "/UK.png",
        value: "en",
      },
    };
  },

  methods: {
    trackClick(str) {
      track.call(this, str);
    },

    scroll(name) {
      this.trackClick(`Click ${name} link`);

      if (this.$route.name === "Home") {
        const el = document.getElementById(name);
        el.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$router.push(`/#${name}`);
      }
    },

    optionSelect(option) {
      this.selected = option;

      this.$i18n.locale = option.value;
      localStorage.setItem("language", JSON.stringify(option));

      this.trackClick(`Choose ${option.name} language`);
    },
  },
};
</script>

<style lang="scss">
.footer {
  padding: 60px 0;
  background: linear-gradient(116.29deg, #2c3e66 9.96%, #1a2948 84.99%);
  box-shadow: inset 0px 50px 80px rgba(6, 12, 26, 0.2);

  .wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 1126px;
    padding-right: 50px;

    .small-text {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      opacity: 0.5;
    }

    .title {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .logo-copyright-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .copyright {
        width: 65%;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #a6cbdf;
        opacity: 0.5;
      }

      .logo-wrapper {
        margin-bottom: 18px;

        .logo-text {
          color: #ffffff;
        }
      }
    }

    .streamline-sales-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: -133px;
      padding-top: 7px;

      .links-list {
        list-style-type: none;

        padding: 0;

        .item {
          padding-top: 16px;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .contact-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 7px;
      margin-left: 6px;

      .title {
        margin-bottom: 16px;
      }

      .small-text {
        margin-bottom: 16px;
        &:hover {
          opacity: 1;
        }
      }

      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
      }
    }

    .social-media-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 7px;
      padding-right: 14px;

      .select-wrapper {
        width: 100%;
      }

      .title {
        margin-bottom: 16px;
        position: relative;
        z-index: 50;
      }

      .social-media-list {
        position: relative;
        z-index: 50;
        display: flex;
        justify-content: space-between;
        width: 192px;
        padding: 0;
        list-style-type: none;

        .item {
          cursor: pointer;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-position: center;
        }

        .facebook-icon {
          background-image: url(../../assets/Facebook.svg);

          &:hover {
            background-image: url(../../assets/Facebook_hover.svg);
          }
        }

        .linkedin-icon {
          background-image: url(../../assets/Linkedin.svg);

          &:hover {
            background-image: url(../../assets/Linkedin_hover.svg);
          }
        }

        .twitter-icon {
          background-image: url(../../assets/Twitter.svg);

          &:hover {
            background-image: url(../../assets/Twitter_hover.svg);
          }
        }

        .youtube-icon {
          background-image: url(../../assets/Youtube.svg);

          &:hover {
            background-image: url(../../assets/Youtube_hover.svg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer,
  #privacy-policy .footer {
    max-height: 400px;
    overflow-y: hidden;

    .wrapper {
      flex-wrap: wrap;
      width: 871px;

      .streamline-sales-wrapper {
        margin-left: -27px;
        padding-top: 7px;
      }

      .contact-wrapper {
        margin-left: 57px;
        margin-right: -7px;
      }

      .streamline-sales-wrapper__fr {
        margin-left: -14px;
      }

      .contact-wrapper__fr {
        margin-left: 57px;
        margin-right: -48px;
      }

      .select-wrapper {
        position: relative;
        top: 9px;
        left: 369px;
      }

      .social-media-list {
        top: -128px;
        left: 649px;
      }

      .title-follow {
        position: relative;
        top: -126px;
        left: 648px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer,
  #privacy-policy .footer {
    padding: 72px 0;
    max-height: 459px;

    .wrapper {
      flex-wrap: wrap;
      width: 680px;

      .streamline-sales-wrapper {
        position: relative;
        top: 122px;
        left: -492px;
      }

      .streamline-sales-wrapper__fr {
        left: -477px;
      }

      .contact-wrapper {
        position: relative;
        top: -69px;
        left: 172px;
      }

      .select-wrapper {
        position: relative;
        top: -70px;
        left: 64px;
      }

      .social-media-list {
        top: -70px;
        left: 64px;
      }

      .title-follow {
        top: -68px;
        left: 64px;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .footer,
  #privacy-policy .footer {
    position: relative;
    padding: 72px 0;
    max-height: none;

    .wrapper {
      flex-wrap: wrap;
      width: 87%;

      .logo-copyright-wrapper {
        margin-bottom: 122px;

        .copyright {
          width: 205px;
        }
      }

      .streamline-sales-wrapper {
        position: relative;
        top: 0;
        left: 0;
        width: 155px;
        margin-left: 0;
        margin-bottom: 33px;
      }

      .contact-wrapper {
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;

        .mb-57 {
          margin-bottom: 41px;
        }

        .mb-32 {
          margin-bottom: 32px;
        }
      }

      .select-wrapper {
        position: absolute;
        top: 208px;
        left: 33px;
      }

      .social-media-list {
        position: relative;
        top: 0;
        left: 52px;
      }

      .title-follow {
        top: 0;
        left: 52px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .footer,
  #privacy-policy .footer {
    .wrapper {
      .social-media-wrapper {
        .select-wrapper {
          left: 26px;
          width: 80%;
        }
      }

      .title-follow {
        left: 0;
      }

      .social-media-list {
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .footer,
  #privacy-policy .footer {
    position: relative;
    padding: 72px 0;
    max-height: none;

    .wrapper {
      flex-wrap: wrap;
      width: 328px;

      .logo-copyright-wrapper {
        margin-bottom: 122px;

        .copyright {
          width: 205px;
        }
      }

      .streamline-sales-wrapper {
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;
        margin-bottom: 33px;
      }

      .contact-wrapper {
        position: relative;
        top: 0;
        left: 0;
        margin-left: 0;

        .mb-57 {
          margin-bottom: 41px;
        }

        .mb-32 {
          margin-bottom: 32px;
        }
      }

      .social-media-wrapper {
        .select-wrapper {
          position: absolute;
          top: 200px;
          left: 16px;
        }
      }

      .social-media-list {
        top: 0;
        left: 0;
      }

      .title-follow {
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
