<template>
  <section class="pricing">
    <h2 class="pricing-title">Pricing</h2>

    <section class="pricing-cards-wrapper">
      <div class="pricing__card">
        <h3 class="card-title">Beginner</h3>
        <p class="duration">Per user, per month</p>
        <p class="price">$0</p>
        <ul class="list">
          <li class="item">1 B2B store</li>
          <li class="item">3 Product SKU</li>
          <li class="item">Print-ready file sent to email</li>
        </ul>
        <button class="get-btn">Get Started</button>
      </div>

      <div class="pricing__card commercial__card">
        <h3 class="card-title">Commercial</h3>
        <p class="duration">Per user, per month</p>
        <p class="price">$9.99</p>
        <ul class="list">
          <li class="item">Unlimited stores</li>
          <li class="item">Unlimited SKUs</li>
          <li class="item">Print-ready file sent to FTP</li>
        </ul>
        <button class="get-btn">Start Trial</button>
      </div>

      <div class="pricing__card">
        <h3 class="card-title">Enterprise</h3>
        <p class="duration">Everything and even more!</p>
        <p class="requirements">Let us know your requirements.</p>
        <ul class="list">
          <li class="item">Unlimited stores</li>
          <li class="item">Unlimited SKUs</li>
        </ul>
        <button class="get-btn">Get Quote</button>
      </div>
    </section>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
.pricing {
  width: 78%;
  margin: 0 auto;
  padding-bottom: 144px;

  .pricing-title {
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #2c3e66;
  }

  .pricing-cards-wrapper {
    display: flex;
    justify-content: space-between;

    .pricing__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 28%;
      padding: 55px 64px;
      text-align: center;
      background: #f3f9fd;
      border: 2px solid rgba(166, 203, 223, 0.5);
      border-radius: 20px;

      .card-title {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #2c3e66;
      }

      .duration {
        margin-bottom: 12px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #2c3e66;
        opacity: 0.5;
      }

      .price {
        margin-bottom: 12px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 64px;
        text-align: center;
        color: #2c3e66;
      }

      .list {
        list-style-image: url("../../assets/pricing_card_marker.svg");
        margin-bottom: 27px;
        padding: 0;
        padding-left: 27px;
        text-align: left;
        list-style-type: none;

        .item {
          margin-bottom: 8px;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #2c3e66;
          opacity: 0.5;
        }
      }

      .get-btn {
        width: 156px;
        height: 48px;
        border-color: transparent;
        outline: none;
        border-radius: 10px;
        background: #bcdef0;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #2c3e66;
        cursor: pointer;
      }

      .requirements {
        margin-bottom: 43px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #2c3e66;
      }
    }

    .commercial__card {
      background: linear-gradient(135deg, #83d2ff 0%, #bbdbed 100%);
      box-shadow: 0px 20px 20px rgba(44, 62, 102, 0.08);
      border-radius: 20px;

      .get-btn {
        background: #2c3e66;
        box-shadow: 0px 50px 80px rgba(58, 134, 255, 0.1);
        color: #ffffff;
      }
    }
  }
}
</style>
